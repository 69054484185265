import {Controller} from "stimulus"

var delayTimer;
export default class extends Controller {

    connect() {
        console.log("tabs controller")
    }

    changeTab(e) {
        e.preventDefault();
        console.log("changing tab!")
        const newTabId = e.target.dataset.tab || e.target.selectedOptions[0].dataset.tab;
        const destinationTab = e.target.closest('.tabs').querySelector(`#${newTabId}`);
        e.target.closest('.tabs').querySelectorAll('.tab').forEach(tab => {
            tab.classList.add('hidden');
        });
        e.target.closest('.tabs').querySelectorAll('.tab-link').forEach(tab => {
            tab.classList.remove(...('border-indigo-500 text-indigo-600'.split(' ')))
            tab.classList.add(...('border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'.split(' ')));
        });
        destinationTab.classList.remove('hidden');
        e.target.classList.add(...('border-indigo-500 text-indigo-600'.split(' ')))


    }
}
