import {Controller} from "stimulus"
import AutoNumeric from "autonumeric"
import Rails from "@rails/ujs";

export default class extends Controller {

    connect() {
        console.log("log from bl-license_applications controller");
    }


    updateRenewalAmountDue(event) {
        console.log("I shouldn't be running updateRenewalAmountDue from bl-license-applications-controller")
    }

    updateLicensePeriods(event) {
        console.log("updating license period select")
        const formArea = event.target.closest('.bl-license-application-form')
        const selectArea = formArea.querySelector(".bl-license-application-license-period-area")
        const licenseTypeId = window.bl_license_application_license_type_id.value;
        const applicationId = formArea.querySelector("#bl_license_application_id").value ||
            formArea.querySelector("#itemReferenceId").innerText || 'new';
        if (licenseTypeId.length === 0) {
            console.log("fill in the license Type")
            formArea.querySelector(".feedback-area").innerHTML += '<p class="no-response feedback-text">License Type is missing!</p>';
        } else {
            fetch('/bl_license_applications/render_license_period_selects?license_type_id=' + licenseTypeId + "&business_license_application_id=" + applicationId)
                .then(response => {
                    return response.text();
                })
                .then(html => {
                    if (formArea.querySelector(".no-response") !== null) {
                        formArea.querySelector(".no-response").remove();
                    }
                    selectArea.innerHTML = html;
                });
        }
    }

    saveApplication(event) {
        if (!(window.location.pathname == "/bl_license_estimators/new")) {
            AutoNumeric.unformatAndSet(document.getElementById('bl_license_application_current_units'))
            if (document.getElementById('bl_license_application_unit_deduction')) {
                AutoNumeric.unformatAndSet(document.getElementById('bl_license_application_unit_deduction'))
            }
            const form = event.target.closest('form');
            Rails.fire(form, 'submit');
        }

    }

}
