import {Controller} from "stimulus"
import AutoNumeric from "autonumeric";

export default class extends Controller {

    connect() {
        console.log("log from memo_entries controller");
    }

    postButtonState(e) {
        const formArea = e.target.closest('.memo-entry-form');
        const dateSelectPresent = formArea.querySelector("#memo_entry_posted_on").value.length > 0;
        const memoDescriptionPresent = formArea.querySelector("#memo_entry_description").value.length > 0;
        const memoAmountPresent = AutoNumeric.getNumber(formArea.querySelector("#memo_entry_amount"));
        const memoSubmitButton = formArea.querySelector(".button");
        memoSubmitButton.disabled = !(dateSelectPresent && memoDescriptionPresent && memoAmountPresent);
    }

}
