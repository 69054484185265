import {Controller} from "stimulus"

export default class extends Controller {

    connect() {
        console.log("hello from business-index");
    }

    updateReport(e) {
        console.log("report changed")
        const contractor = document.getElementById('is_contractor').checked;
        const isActive = document.getElementById('is_active').checked;
        const isLicensed = document.getElementById('is_licensed').checked;
        const jurisdiction = document.getElementById("jurisdiction_id")
        const residency = document.getElementById("residency")
        const classification = document.getElementById("classification")
        const name = document.getElementById("name").value
        let reportUrl = 'businesses?classification=' + classification.value
        if (contractor) {
            reportUrl = reportUrl + '&is_contractor=true'
        }
        if (isActive) {
            reportUrl = reportUrl + '&is_active=true'
        }
        if (isLicensed) {
            reportUrl = reportUrl + '&is_licensed=true'
        }
        if (jurisdiction) {
            reportUrl = reportUrl + '&jurisdiction_id=' + jurisdiction.value
        }
        if (residency) {
            reportUrl = reportUrl + '&residency=' + residency.value
        }
        if (name) {
            reportUrl = reportUrl + '&name=' + name
        }
        window.location.href = reportUrl;
    }
}