import {Controller} from "stimulus"

var delayTimer;
export default class extends Controller {

    connect() {
        console.log("log from graphics controller");
    }

    showGraphic(e) {
        e.preventDefault();
        const graphicId = e.target.closest('.graphic-card').querySelector(".graphic-id").value;
        fetch("/graphics/" + graphicId + "/edit.js  ", {
            credentials: "same-origin",
            headers: {
                "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content,
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            }
        }).then(response => response.text())
            .then(html => {
                    const displayArea = document.querySelector(".display-area");
                    displayArea.innerHTML = html;
                    displayArea.classList.remove('hidden');

                }
            );

    }

    deleteGraphic(e) {
        console.log("delete graphic");
        let deleteConfirmed = confirm("Are you sure you want to delete this image?");
        if (deleteConfirmed) {
            const graphicId = e.target.closest('.graphic-overlay').querySelector(".graphic-id").value;
            fetch("/graphics/" + graphicId, {
                method: 'DELETE',
                credentials: "same-origin",
                headers: {
                    "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content,
                    "Content-Type": "application/json"
                }
            }).then(response => response.text())
                .then(response => {
                        eval(response);
                    }
                );
        }
    }

    closeForm(e) {
        const displayArea = document.querySelector(".display-area");
        displayArea.classList.add('hidden');

    }

}
