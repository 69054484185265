import {Controller} from "stimulus";
import {validateFields, formSubmittable} from "../../../lib/assets/form-validation";

let numberElements;
export default class extends Controller {

    connect() {
        console.log("log from assessments controller");

    }

    updateReport(e) {
        console.log("report changed")
        const status = document.getElementById("status")
        window.location.href = 'assessments?status=' + status.value;
    }

    validateForm(e) {
        const assessmentFields = e.target.closest(".assessment-fields")
        validateFields(assessmentFields);
        formSubmittable(assessmentFields);
    }

    checkAppealDate(e) {
        console.log("appeals date changed...validating")
        const dueDate = Date.parse(document.getElementById('licensing_assessment_appealed_on').dataset.deadline);
        const appealDate = Date.parse(document.getElementById('licensing_assessment_appealed_on').value);
        if (appealDate > dueDate) {
            document.querySelector('#flash-area').innerHTML = "<p class=\"warn\">Appeal submitted After Deadline</p>";
        } else {
            document.querySelector('#flash-area').innerHTML = '';
        }
    }

}
