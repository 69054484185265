import {Controller} from "stimulus"
import Rails from '@rails/ujs';

let currentSlideId = "1";
let isNavigating = false;

export default class extends Controller {
    static get targets() {
        return ["businessForm", "lineItemClass", "primaryContactForm"]
    }


    connect() {
        console.log("log from bl-internal-license-wizard controller");
        currentSlideId = "1";
        hideUnneededLicenseApplicationFields();
    }

    previousSlide(e) {
        if (isNavigating) return;
        isNavigating = true;
        goToPreviousSlide();
        setTimeout(() => {
            isNavigating = false;
        }, 750); // 500ms delay
        document.getElementById("flash-area").innerHTML = "" // clear flash messages
    }


    nextSlide(e) {
        if (isNavigating) return;
        isNavigating = true;
        document.getElementById("slide-" + currentSlideId).classList.add("hidden");
        document.getElementById("wizard-nav").querySelector(".slide-" + currentSlideId).classList.remove("selected")
        currentSlideId = String(Number(currentSlideId) + 1);
        document.getElementById("slide-" + currentSlideId).classList.remove("hidden");
        document.getElementById("wizard-nav").querySelector(".slide-" + currentSlideId).classList.add("selected")
        setTimeout(() => {
            isNavigating = false;
        }, 750); // 500ms delay
        document.getElementById("flash-area").innerHTML = "" // clear flash messages

    }

    selectClassification(e) {
        const classificationTypeId = e.currentTarget.id;
        const classificationField = document.querySelector("#bl_license_application_classification_id");
        classificationField.value = classificationTypeId;
        slideOneReady();
    }

    setClassification(e) {
        // move html element with id bl_license_application_classification_id to inside the form inside the div with the class 'application-form'
        const classificationField = document.querySelector("#bl_license_application_classification_id");
        const licenseForm = document.querySelector(".application-form").querySelector("form");
        licenseForm.appendChild(classificationField);

    }


    selectLicenseType(e) {
        const licenseTypeField = document.getElementById("bl_license_application_license_type_id");
        const licenseTypeId = e.currentTarget.id;
        licenseTypeField.value = licenseTypeId;
    }

    updateWizardSteps(e) {
        const licenseTypeField = document.getElementById("bl_license_application_license_type_id");
        const licenseTypeId = licenseTypeField.value;
        const classificationField = document.querySelector("#bl_license_application_classification_id");
        const classificationTypeId = classificationField.value;
        document.querySelector(".slide-controls-1").classList.add('hidden');
        //load subsequent wizard steps
        //fetch wizard_steps from licensing_wizard_steps_controller
        fetch('/licensing/wizard_steps.html?license_type_id=' + licenseTypeId + '&classification_id=' + classificationTypeId, {
            method: 'GET'
        }).then(response => response.text())
            .then(data => {
                const dataBlock = JSON.parse(data);
                console.log(dataBlock);
                e.target.closest(".wizard").querySelector("#wizard-nav").innerHTML = dataBlock['nav'];
                e.target.closest(".wizard-steps").innerHTML = dataBlock['html'];
                const licenseTypeField = document.getElementById("bl_license_application_license_type_id");
                licenseTypeField.value = licenseTypeId;
                hideUnneededLicenseApplicationFields();
                slideOneReady();
            });
    }

    createBusiness(e) {
        document.getElementById('bl_license_application_residency').checked = document.getElementById('business_business_is_local').checked;
        const businessForm = this.businessFormTarget;
        Rails.fire(businessForm, 'submit');
    }

    setBusinessInfo(e) {
        console.log("Setting Business ID in Location");
        const businessIdField = document.querySelector("#business_location_business_id");
        businessIdField.value = JSON.parse(e.detail[2].response).business['id'];
        document.querySelector(".business-form").innerHTML = JSON.parse(e.detail[2].response).form_partial;
    }


    slideOneReady() {
        slideOneReady();
    }

    slideTwoReady() {
        slideTwoReady();
    }

    slideFourReady() {
        let readyState = false
        const useLocationCheckbox = document.getElementById('location_is_contact').checked
        if (useLocationCheckbox) {
            readyState = true;
        } else {
            readyState = Array.from(document.querySelectorAll(".contact-submittable")).map(field => {
                return field.value === 'true'
            }).every(Boolean)
        }
        if (readyState) {
            document.querySelector(".slide-next-4").classList.remove('hidden');
        } else {
            document.querySelector(".slide-next-4").classList.add('hidden');
        }

    }

    allowAddressSave(e) {
        console.log("Allow Address Save! SHOULD NOT BE HERE");
        // const addressForm = document.querySelector(".address-form");
        // const saveButton = addressForm.querySelector(".save-address-button");
        // const streetOneField = addressForm.querySelector("#address_street_one").value;
        // const cityField = addressForm.querySelector("#address_city").value;
        // const stateField = addressForm.querySelector("#address_state").value;
        // const zipField = addressForm.querySelector("#address_zip").value;
        // if (streetOneField.length == 0 || cityField.length == 0 || stateField.length == 0 || zipField.length == 0) {
        //     saveButton.disabled = true;
        // } else {
        //     saveButton.disabled = false;
        // }

    }

    saveAddress(e) {
        console.log("Address Saved! SHOULD NOT BE HERE");
        const locationField = window.location_id;
        const locationKlassField = window.location_klass;
        locationField.value = window.address_id.value;
        locationKlassField.value = "Address";
        const address_card = JSON.parse(e.detail[2].response).html
        document.querySelector(".business-location-search-form").classList.add("hidden");
        document.querySelector(".new-business-location-form").classList.add("hidden");
        document.querySelector('.address-results').innerHTML = "<p class=\"notice\">Address Set!</p>" + address_card;
        Array.from(e.target.closest(".business-location-search-container")
            .querySelectorAll(".business-location-search-results")).map(results => {
            results.classList.add("hidden")
        });
        slideThreeReady();
    }


    selectAddress(e) {
        setTimeout(function () {
            slideThreeReady();
        }, 1000);
    }

    showContactForm(e) {
        const contactForm = document.querySelector(".primary_contact_form");
        if (document.getElementById('location_is_contact').checked) {
            contactForm.classList.add('hidden')
        } else {
            contactForm.classList.remove('hidden')
        }
    }


    createBusinessLocation(e) {
        console.log("Wizard createBusinessLocation SHOULD NOT BE HERE");
        // const locationField = window.location_id;
        // const locationKlassField = window.location_klass;
        // const businessIdField = window.business_id;
        // if (locationField.value.length > 0) {
        //     document.getElementById("flash-area").innerHTML = "";
        //     let data = {
        //         business_location_params: {
        //             addressableId: locationField.value,
        //             addressableKlass: locationKlassField.value,
        //             businessId: businessIdField.value
        //         }
        //     };
        //     fetch('/business_locations/wizard_create', {
        //         method: 'POST',
        //         credentials: "same-origin",
        //         headers: {
        //             "X-CSRF-Token": document.getElementsByName('csrf-token')[0].content,
        //             "Content-Type": "application/json"
        //         },
        //         body: JSON.stringify(data)
        //     }).then(response => response.json())
        //         .then((data) => {
        //             console.log(data);
        //             const businessIdField = document.getElementById("business_id");
        //             const applicationLicensableIdField = document.getElementById("bl_license_application_licensable_id");
        //             const applicationLicensableTypeField = document.getElementById("bl_license_application_licensable_type");
        //             applicationLicensableIdField.value = data['licensable_id'];
        //             applicationLicensableTypeField.value = data['licensable_type'];
        //             slideThreeReady();
        //         })
        //         .catch((error) => console.log(error)
        //         )
        // } else {
        //     {
        //         document.getElementById("flash-area").innerHTML = "<p class='alert'> Sorry! The Business Location Was Not Set Properly; please search and try again</p>"
        //         goToPreviousSlide();
        //     }
        // }

    }

    setBusinessLocation(e) {
        const licenseApplicationForm = document.querySelector(".bl-license-application-form");
        const licensableIdField = licenseApplicationForm.querySelector("#bl_license_application_licensable_id");
        const licensableTypeField = licenseApplicationForm.querySelector("#bl_license_application_licensable_type");
        const response = JSON.parse(e.detail[2].response)
        const licensableId = response.licensable_id;
        const licensableType = response.licensable_type;
        if (licensableIdField) {
            console.log("setting Location from save");
            licensableIdField.value = licensableId;
            licensableTypeField.value = licensableType;
            slideThreeReady();
            document.querySelector(".wizard-licensable-submit").classList.add('hidden');
            document.getElementById("flash-area").innerHTML = "<p class='notice'> Business Location Set</p>"

        } else {
            {
                document.getElementById("flash-area").innerHTML = "<p class='alert'> Sorry! The Business Location Was Not Set Properly; please search and try again</p>"
                goToPreviousSlide();
            }
        }

    }

    // tied to 'Next' button on slide four, business location contact information, saves the contact info
    createPrimaryContactInfo(e) {
        const businessIdField = window.business_id;
        if (document.getElementById('location_is_contact').checked == false) {
            const contactRelationForm = this.primaryContactFormTarget;
            contactRelationForm.querySelector("#contact_relation_contactable_id").value = businessIdField.value;
            contactRelationForm.querySelector("#contact_relation_contactable_type").value = 'Business';
            Rails.fire(contactRelationForm, 'submit');
        } else {
            fetch('/businesses/' + businessIdField.value + '/contact_info_set_to_location')
                .then(response => {
                    return response.text();
                })
                .then(html => {
                })
        }

    }

    setTaskTypes(e) {
        e.preventDefault();
        console.log('Adding tasks');
        const taskType = e.target.dataset.id;
        const taskDescription = e.target.dataset.description
        const applicationTaskTypeField = document.getElementById("bl_license_application_task_type_ids")
        e.target.closest('li').classList.toggle('selected');
        const currentTasksFields = e.target.closest('.add-subset').querySelectorAll('.selected')
        applicationTaskTypeField.value = Array.from(currentTasksFields).map(e => {
            return e.children[0].dataset.id
        })
        // const currentTasks = applicationTaskTypeField.value.split(',');
        // const allTasks = [...new Set((currentTasks.concat(taskType)).filter(function (e) {
        //     return e !== ''
        // }))]
        document.getElementById("flash-area").innerHTML = `<p class="notice">Task Added: ${taskDescription}</p>`;
    }


}

function hideUnneededLicenseApplicationFields() {
    // document.querySelector(".select-license-type-by-classification").classList.add('hidden')
}

function goToPreviousSlide() {
    document.getElementById("slide-" + currentSlideId).classList.add("hidden");
    document.getElementById("wizard-nav").querySelector(".slide-" + currentSlideId).classList.remove("selected")
    currentSlideId = String(Number(currentSlideId) - 1);
    document.getElementById("slide-" + currentSlideId).classList.remove("hidden");
    document.getElementById("wizard-nav").querySelector(".slide-" + currentSlideId).classList.add("selected")
}

function slideOneReady() {
    const licenseTypeField = document.getElementById("bl_license_application_license_type_id");
    const checkClassification = document.getElementById('require-classification');
    const classificationField = document.getElementById("bl_license_application_classification_id");
    if (licenseTypeField == null || licenseTypeField.value.length == 0 && (checkClassification != null && classificationField.value.length == 0)) {
        document.querySelector(".slide-controls-1").classList.add('hidden');
    } else {
        document.querySelector(".slide-controls-1").classList.remove('hidden');
    }
}


function slideTwoReady() {
    console.log('slide 2 ready?')
    const businessNameField = window.business_business_name;
    if (businessNameField.value.length == 0) {
        document.querySelector(".slide-next-2").classList.add('hidden');
    } else {
        document.querySelector(".slide-next-2").classList.remove('hidden');
    }
}


function slideThreeReady() {
    console.log('slide 3 ready?')
    const licenseApplicationForm = document.querySelector(".bl-license-application-form");
    const licensableIdField = licenseApplicationForm.querySelector("#bl_license_application_licensable_id");
    if (licensableIdField.value.length == 0) {
        document.querySelector(".slide-next-3").classList.add('hidden');
    } else {
        document.querySelector(".slide-next-3").classList.remove('hidden');
    }

}


