import {Controller} from "stimulus"
import AutoNumeric from "autonumeric";

export default class extends Controller {

    connect() {
        console.log("log from bl-check-requests controller");
    }

    postButtonState(e) {
        console.log('checking bl check request form readiness')
        const formArea = e.target.closest("#request-refund").querySelector('.bl-request-form');
        const setAmount = formArea.querySelector("#bl_check_request_amount").value;
        if (setAmount !== AutoNumeric.getNumber(formArea.querySelector("#bl_check_request_amount"))) {
            AutoNumeric.set(formArea.querySelector("#bl_check_request_amount"), setAmount);
        }
        const dateSelectPresent = formArea.querySelector("#bl_check_request_when_booked").value.length > 0;
        const memoAmountPresent = AutoNumeric.getNumber(formArea.querySelector("#bl_check_request_amount"));
        const memoSubmitButton = formArea.querySelector(".button");
        memoSubmitButton.disabled = !(dateSelectPresent && memoAmountPresent);
    }

    updateBalance(e) {

    }

}
